import React, { useState, useCallback, useEffect, useRef } from 'react';
import axios from 'axios';
import { createNewFolderFromAPI, fetchFoldersFromAPI } from './FolderUtils';
import './buttons.css';
import './Dashboard.css';


function CreateFlashCard({ onFlashCardCreated = () => {} }) {
  const [frontContent, setFrontContent] = useState('');
  const [backContent, setBackContent] = useState('');
  const [folders, setFolders] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState('');
  const [message, setMessage] = useState('');
  const [showManualDialog, setManualDialog] = useState(false);
  const blockRef = useRef(null);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the block
      if (blockRef.current && !blockRef.current.contains(event.target)) {
        setManualDialog(false); // Close the dialog if the click is outside
      }
    };

    // Add event listener for clicks outside the dialog
    document.addEventListener('click', handleClickOutside);

    // Cleanup event listener on unmount
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleFetchFolders = useCallback(async () => {
    const userId = localStorage.getItem('userId');
    try {
      const folderData = await fetchFoldersFromAPI(API_BASE_URL, userId);
      setFolders(folderData);
    } catch (error) {
      console.error('Error fetching folders:', error);
    }
  }, [API_BASE_URL]);

  const handleCreateFolder = async () => {
    const folderName = prompt('Please enter folder name:');
    if (!folderName) return;
    const userId = localStorage.getItem('userId');

    try {
      const newFolder = await createNewFolderFromAPI(API_BASE_URL, userId, folderName);
      setFolders((prev) => [...prev, newFolder]);
    } catch (error) {
      console.error('Error creating folder:', error);
    }
  };

  useEffect(() => {
    handleFetchFolders();
  }, [handleFetchFolders]);


  const handleCreateFlashCard = async () => {
    if (!frontContent || !backContent) {
      setMessage('Please fill in both fields');
      return;
    }
    console.log('Creating Flashcard with:', {front_content: frontContent, back_content: backContent, folder_name: selectedFolder});
    try {
      const userId = localStorage.getItem('userId');
      console.log('Retrieved user ID:', userId);
      if (!userId || isNaN(userId)) {
        setMessage ('User ID is invalid. Please log in again');
        return;
      }
      const response = await axios.post(`${API_BASE_URL}/api/flashcards`, {
        front_content: frontContent,
        back_content: backContent,
        difficulty_level: 3, // Default difficulty level
        user_id: userId,
        folder_name: selectedFolder
      });
    console.log('Flashcard creation response:', response.data);
      if (response.data) {
        onFlashCardCreated(response.data); // Call the function to update the flashcard list
        console.log('Flashcard created and passed to parent:', response.data); 
        setFrontContent('');
        setBackContent('');
        setMessage('Flashcard created successfully!');
      }
    } catch (error) {
      console.error('Error creating flashcard:', error.response ? error.response.data : error);
      setMessage('Error creating flashcard');
    }
  };


  return (
    <div className='dashboard-block' 
      ref={blockRef} // Attach ref to the block
      onClick={(e) => {
        // Prevent click propagation inside block
        e.stopPropagation(); 
        setManualDialog(true); // Open the dialog when clicked
      }}
    >
      <h2>Manual Create</h2>
      {showManualDialog && (
        <div className='create-container'>
            <button  className='back' type="button" onClick={handleCreateFolder}>
              Create New Folder
            </button>
            <select className='dropdown'
              value={selectedFolder}
              onChange={(e) => setSelectedFolder(e.target.value)}                    
            >
              <option value="">Select Folder</option>
              {folders.map((folder) => (
                <option key={folder.id} value={folder.folder_name}>
                  {folder.folder_name}
                </option>
              ))}
            </select>
            {/* Show choose file after selected a folder */}
            {selectedFolder!='' && (
              <div>
                <div>
                  <input
                        className='manual-input' 
                        type="text"
                        placeholder="Front Content"
                        value={frontContent}
                        onChange={(e) => setFrontContent(e.target.value)}
                      />
                </div>
                <div>
                  <input
                        className='manual-input'
                        type="text"
                        placeholder="Back Content"
                        value={backContent}
                        onChange={(e) => setBackContent(e.target.value)}
                      />
                </div>
                    <button className='manual-create' onClick={handleCreateFlashCard}>Create</button>
                    <p>{message}</p>
              </div>
            )}                  
        </div>
      )}
    </div>
  );
}

export default CreateFlashCard;
