import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import FlashCard from './FlashCard';
import { createNewFolderFromAPI, fetchFoldersFromAPI } from './FolderUtils';
//import CreateFlashCard from './CreateFlashCard';
import { useNavigate } from 'react-router-dom';
import './buttons.css';
import './FlashCardList.css'

function FlashCardList() {
    //console.log('Flashcard list component rendered');
    const [flashcards, setFlashcards] = useState([]);
    const [totalFlashcards, setTotalFlashcards] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [flippedCards, setFlippedCards] = useState({}); // Track flipped state for each card
    const [selectedCards, setSelectedCards] = useState(new Set());
    const [editMode, setEditMode] = useState({});
    const [folders, setFolders] = useState([]);
    const [selectedFolder, setSelectedFolder] = useState('');
    const [selectedFoldertoMove, setSelectedFoldertoMove] = useState('');
    const [showDeleteDialog, setShowDeleteDialog] = useState(false); // Manage dialog visibility
    const [showMoveDialog, setMoveDialog] = useState(false);
    const [selectedFoldersToDelete, setSelectedFoldersToDelete] = useState(new Set()); // Track selected folders
    const navigate = useNavigate();
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;



    // Fetch folders
    const fetchFolders = useCallback(async () => {
        const userId = localStorage.getItem('userId');
        try {
            //const response = await axios.get(`${API_BASE_URL}/api/flashcards/folders?user_id=${userId}`); 
            //setFolders(response.data);
            const folderData = await fetchFoldersFromAPI(API_BASE_URL, userId);
            setFolders(folderData);
            //console.log('Fetched folders:', response.data); // Log the fetched data
        } catch (error) {
            console.error('Error fetching folders:', error);
            setError('Error fetching folders');
        }
    }, [API_BASE_URL]);

    useEffect(() => {
        fetchFolders(); // NEW: Fetch folders on load
    }, [fetchFolders]);



    const fetchFlashcards = useCallback(async () => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await axios.get(`${API_BASE_URL}/api/flashcards?user_id=${userId}`, {
                params: { folder: selectedFolder || '' },
            });
            console.log('Fetched flashcards:', response.data); // Log the fetched data
            setTotalFlashcards(response.data.length); // Set total flashcards
            setFlashcards(response.data);
            setLoading(false); // Ensure loading is set to false after fetching
        } catch (error) {
            console.error('Error fetching flashcards:', error);
            setError('Error fetching flashcards');
        } finally {
            setLoading(false); // Ensure loading is set to false even on error
        }
    }, [API_BASE_URL, selectedFolder]);

    useEffect(() => {
        fetchFlashcards();
    }, [fetchFlashcards]);
    

    const createNewFolder = async () => { // NEW: Function to create a folder
        const folderName = prompt('Please enter folder name:');
        if (!folderName) return;
        const userId = localStorage.getItem('userId');

        try {
            //const response = await axios.post(`${API_BASE_URL}/api/flashcards/folders`, { user_id: userId, folder_name: folderName });
            //setFolders((prev) => [...prev, response.data.folder]); // Add new folder to state
            const newFolder = await createNewFolderFromAPI(API_BASE_URL, userId, folderName);
            setFolders((prev) => [...prev, newFolder]);    
        } catch (error) {
            console.error('Error creating folder:', error);
        }
    };



    // Function to toggle folder selection
    const toggleFolderSelection = (folderId) => {
        setSelectedFoldersToDelete((prev) => {
            const updated = new Set(prev);
            if (updated.has(folderId)) {
                updated.delete(folderId); // Deselect the folder
            } else {
                updated.add(folderId); // Select the folder
            }
            return updated;
        });
    };

   // Function to confirm deletion
    const confirmDeleteFolders = async () => {
    const userId = localStorage.getItem('userId');
    try {
        const folderNamesToDelete = Array.from(selectedFoldersToDelete).map(
            (folderId) => folders.find((folder) => folder.id === folderId).folder_name
        );

        console.log('Folder names to delete:', folderNamesToDelete); // Log the folder names

        
        await axios.delete(`${API_BASE_URL}/api/flashcards/folders`, {
            data: {
                user_id: userId,
                folder_names: folderNamesToDelete,
            },
        });

        
        setFolders((prev) =>
            prev.filter((folder) => !selectedFoldersToDelete.has(folder.id))
        );
        setSelectedFoldersToDelete(new Set()); // Reset selection
        fetchFlashcards(); // Refresh flashcards after deletion
        setShowDeleteDialog(false); // Close the delete dialog
    } catch (error) {
        console.error('Error deleting folders:', error);
    }
};

    // Handle moving flashcards
    const handleMove = async () => {
        if (!selectedFoldertoMove) {
            alert('Please select a folder.');
            return;
        }

        try {
            await axios.put(`${API_BASE_URL}/api/flashcards/move`, {
                flashcard_ids: Array.from(selectedCards),
                folder: selectedFoldertoMove,
            });
            fetchFlashcards(); // Refresh flashcards after moving
            alert(`Flashcards moved to folder: ${selectedFoldertoMove}`);
            setMoveDialog(false);
        } catch (error) {
            console.error('Error moving flashcards:', error);
        }
    };



    const GoDashboard = () => {
        navigate('/dashboard'); // Back to the home page.
    };

    const toggleSelectCard = (id) => {
        setSelectedCards((prevSelected) => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(id)) {
                newSelected.delete(id); // Deselect the card
            } else {
                newSelected.add(id); // Select the card
            }
            return newSelected;
        });
    };

    const selectAll = () => {
   	if (selectedCards.size === flashcards.length) {
		// if all cards are selected, deselect them
		setSelectedCards(new Set());
	} else {
		//otherwise select all cards
		const allCardIds = flashcards.map(card => card.id);
		setSelectedCards(new Set(allCardIds));
	}
    };

    const deleteSelectedCards = useCallback(async () => {
        const idsToDelete = Array.from(selectedCards); // Convert Set to Array
        try {
            await Promise.all(idsToDelete.map(id => 
                axios.delete(`${API_BASE_URL}/api/flashcards/${id}`)
            ));
            setFlashcards((prevCards) => prevCards.filter(card => !idsToDelete.includes(card.id))); // Remove deleted cards from state
            setSelectedCards(new Set()); // Clear selected cards
            console.log(`Flashcards with ids ${idsToDelete.join(', ')} deleted successfully.`);
        } catch (error) {
            console.error('Error deleting flashcards:', error);
        }
    }, [selectedCards, API_BASE_URL]);

    //console.log('Current flashcards:', flashcards); // Log the current flashcards
    if (loading) return <p>Loading flashcards...</p>;
    if (error) return <p>{error}</p>;
    if (flashcards.length === 0) return (
        <div>
            {selectedFolder ? (
            <>
                <p>No flashcards in "{selectedFolder}" folder. Please add some flashcards to the folder.</p>
                <button className="back" onClick={ () => setSelectedFolder('') }>Back</button>
            </>
            ) : (
            <>
                <p>No flashcards available. Please add some.</p>
                <button className="back" onClick={GoDashboard}>Back</button>
            </>

            )}
            
        </div>
    );

    const toggleFlip = (id) => {
        setFlippedCards((prev) => ({
            ...prev,
            [id]: !prev[id], // Toggle the flip state for the specific card
        }));
    };

    const handleEdit = (cardId) => {
        // Set the card in edit mode
        setEditMode((prev) => ({
            ...prev,
            [cardId]: { 
                frontContent: flashcards.find(card => card.id === cardId).front_content,
                backContent: flashcards.find(card => card.id === cardId).back_content,
                level: flashcards.find(card => card.id === cardId).difficulty_level,
            }
        }));
    };

    const handleEditSave = async (cardId, frontContent, backContent, level) => {
        try {
            await axios.put(`${API_BASE_URL}/api/flashcards/${cardId}`, {
                front_content: frontContent,
                back_content: backContent,
                difficulty_level: level
            });
            const updatedFlashcards = flashcards.map((card) =>
                card.id === cardId ? { ...card, front_content: frontContent, back_content: backContent, difficulty_level: level } : card
            );
            setFlashcards(updatedFlashcards);
            setEditMode((prev) => {
                const updated = { ...prev };
                delete updated[cardId]; // Remove the card from the edit mode
                return updated;
            });
        } catch (error) {
            console.error('Error saving flashcard:', error);
        }
    };

    const handleEditCancel = (cardId) => {
        // Cancel editing, reset content to previous state
        setEditMode((prev) => {
            const updated = { ...prev };
            delete updated[cardId]; // Remove the card from the edit mode
            return updated;
        });
    };

    const levelDescriptions = {
        1: "Very Easy",
        2: "Easy",
        3: "Normal",
        4: "Difficult",
        5: "Very Difficult",
    };
 
    return (
        <div>
            <h2>My Flashcards</h2>
            <button className="back" onClick={GoDashboard}>Back</button>
            <div>
            <select value={selectedFolder} onChange={(e) => setSelectedFolder(e.target.value) } className="dropdown">
                <option value="">All Folders {selectedFolder=="" && `(${totalFlashcards})`}</option>
                {folders.map((folder) => (
                    <option key={folder.id} value={folder.folder_name}>{folder.folder_name} {selectedFolder===folder.folder_name && `(${totalFlashcards})`}</option>
                ))}
            </select>
            <button className="back" onClick={createNewFolder}>Create Folder</button>
            
            {/* Button to open delete folder dialog */}
            <button className="back" onClick={() => setShowDeleteDialog(true)}>Delete Folder</button>

            {/* Delete Folder Dialog */}
            {showDeleteDialog && (
                <div className="dialog">
                    <h3>Select Folders to Delete</h3>
                    <div className="folder-list">
                        {folders.map((folder) => (
                            <label key={folder.id}>
                                <input
                                    type="checkbox"
                                    checked={selectedFoldersToDelete.has(folder.id)}
                                    onChange={() => toggleFolderSelection(folder.id)}
                                />
                                {folder.folder_name}
                            </label>
                        ))}
                    </div>
                    <div className="dialog-actions">
                        <button className="back" onClick={confirmDeleteFolders} disabled={selectedFoldersToDelete.size === 0}>
                            Confirm Delete
                        </button>
                        <button className="back" onClick={() => setShowDeleteDialog(false)}>Cancel</button>
                    </div>
                </div>
            )}
            </div>


            {/*flashcards.length === 0 && <CreateFlashCard onFlashCardCreated={handleFlashCardCreated} />*/}
        <div>
		    <button className="select-all" onClick={selectAll}>
			{selectedCards.size === flashcards.length ? 'Deselect All' : 'Select All'}
        	</button>
            <button className="delete-all" onClick={deleteSelectedCards} disabled={selectedCards.size === 0}>Delete All</button>
            <button className="back" onClick={() => setMoveDialog(true)} disabled={selectedCards.size === 0}>Move To</button>
 
            {showMoveDialog && (
            <div className="move-dialog" >
                <select
                    value={selectedFoldertoMove}
                    onChange={(e) => setSelectedFoldertoMove(e.target.value)}
                    disabled={folders.length === 0 || selectedCards.size === 0}
                    className='dropdown'
                >
                    <option value="" disabled>
                        Select a folder
                    </option>
                    {folders.map((folder) => (
                        <option key={folder.id} value={folder.folder_name}>
                            {folder.folder_name}
                        </option>
                    ))}
                </select>
                <button className="back" onClick={handleMove} disabled={!selectedFoldertoMove}>
                    Confirm Move
                </button>
                <button className="back" onClick={() => setMoveDialog(false)}>Cancel</button>
            </div>
            )}
 
	    </div>
        
        {/* Flashcard List starts from here */}
	     <div>
                {flashcards.map((card) => (

                    <div key={card.id}>
                        {/* Flashcard Only visible if not in edit mode */}
                        {!editMode[card.id] && (
                        <FlashCard 
                            card={card} 
                            isFlipped={flippedCards[card.id] || false} // Pass the flip state
                            onFlip={() => toggleFlip(card.id)} // Pass the toggle function
                        />
                        )}
                        
                        {/* Editable Fields (When in Edit Mode) */}
                        {editMode[card.id] && (
                            <div className="editable-card">
                                <input
                                    type="text"
                                    value={editMode[card.id].frontContent}
                                    onChange={(e) => setEditMode((prev) => ({
                                        ...prev,
                                        [card.id]: { ...prev[card.id], frontContent: e.target.value }
                                    }))}
                                />
                                <textarea
                                    value={editMode[card.id].backContent}
                                    onChange={(e) => setEditMode((prev) => ({
                                        ...prev,
                                        [card.id]: { ...prev[card.id], backContent: e.target.value }
                                    }))}
                                />
                                <select
                                    className='dropdown'
                                    value={editMode[card.id].level} // highlight the current level
                                    onChange={(e) => setEditMode((prev) => ({
                                        ...prev,
                                        [card.id]: { ...prev[card.id], level: e.target.value }
                                    }))}
                                >
                                    {/* Descriptive options */}
                                    <option value={1}>Very Easy</option>
                                    <option value={2}>Easy</option>
                                    <option value={3}>Normal</option>
                                    <option value={4}>Difficult</option>
                                    <option value={5}>Very Difficult</option>
                                </select>
                            </div>
                        )}

                        {/* Edit Button (Only visible if not in edit mode) */}
                          {!editMode[card.id] ? (
                            <div>
                                <div>                        
                                    {/* Display folder name if assigned and not select a folder yet */}
                                    {!selectedFolder && card.folder && (                            
                                        <label className="folder-label">
                                            Folder : {card.folder}
                                        </label>
                                    )}
                                    <div>
                                        <label className="folder-label">
                                            level : {levelDescriptions[card.difficulty_level]}
                                        </label>
                                    </div> 
                                </div>
                            <input
                                type="checkbox" 
                                checked={selectedCards.has(card.id)} 
                                onChange={() => toggleSelectCard(card.id)}
                            />    
                            <button className="edit" onClick={() => handleEdit(card.id)}>Edit</button>
                            </div> 
                            
                        ) : (
                            <div>
                                {/* Save and Cancel buttons when in edit mode */}
                                <button className="save" onClick={() => handleEditSave(card.id, editMode[card.id].frontContent, editMode[card.id].backContent, editMode[card.id].level)}>Save</button>
                                <button className="cancel" onClick={() => handleEditCancel(card.id)}>Cancel</button>
                                <button className="delete-selected" onClick={deleteSelectedCards} disabled={selectedCards.size === 0}>Delete</button>
                            </div>
                        )}
                    </div> 
                ))}
            </div>    
            <button className="back" onClick={GoDashboard}>Back</button>
        </div>
    );
}


export default FlashCardList;
