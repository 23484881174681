import React from 'react';
import './FlashCard.css'; // Ensure you have styles for flipping

function FlashCard({ card, onFlip, isFlipped }) {
    if (!card) {
        return <div>Card not found</div>;
    };

    //console.log('Current Card:', card);

    const handleFlip = () => {
        onFlip(); // Call the onFlip function passed from the parent
    };

    return (
        <div className='flashcard-container'>
            <div className={`flashcard ${isFlipped ? 'flipped' : ''}`} onClick={handleFlip}>
                <div className="flashcard-inner">
                    <div className="flashcard-front">
                        <h3>{card.front_content}</h3>
                    </div>
                    <div className="flashcard-back">
                        <p>{card.back_content}</p>
                    </div>
                </div>
            </div>
        </div>
);
}

export default FlashCard;