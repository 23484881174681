import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FileUpload from './components/FileUpload';
import CreateFlashCard from './components/CreateFlashCard';
import FlashCardList from './components/FlashCardList';
import logo from './logo.svg';
import Home from './components/Home';
import SignIn from './components/SignIn';
import SignUp from './components/SignUp';
import Dashboard from './components/Dashboard';
import GameModeSelection from './components/GameModeSelection';
import Play from './components/Play';
import PlayAI from './components/PlayAI';
import PrivateRoute from './components/PrivateRoute';
import Statistic from './components/Statistic';
import Admin from './components/Admin';
import './App.css';

function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>FlashCard AI</h1>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sign-in" element={<SignIn />} />
            <Route path="/sign-up" element={<SignUp />} />
            <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
            <Route path="/.flashupload" element={<PrivateRoute><FileUpload /></PrivateRoute>} />
            <Route path="/create-flashcard" element={<PrivateRoute><CreateFlashCard /></PrivateRoute>} />
            <Route path="/flashcard-list" element={<PrivateRoute><FlashCardList /></PrivateRoute>} />
            <Route path="/game-mode" element={<PrivateRoute><GameModeSelection /></PrivateRoute>} />
            <Route path="/play" element={<PrivateRoute><Play /></PrivateRoute>} />
            <Route path="/playAI" element={<PrivateRoute><PlayAI /></PrivateRoute>} />
            <Route path="/statistic" element={<PrivateRoute><Statistic /></PrivateRoute>} />
            <Route path="/admin" element={<PrivateRoute><Admin /></PrivateRoute>} />
          </Routes>
        </header>
      </div>
    </Router>
  );
}

export default App;
