import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './buttons.css';
import './Admin.css';

function Admin() {
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();
  const [selectedUser, setSelectedUser] = useState(''); // Selected User
  const [selectedAction, setSelectedAction] = useState(''); // Selected Action
  const [totalUsers, setTotalUsers] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

  const GoDashboard = () => {
    navigate('/dashboard'); // Back to the dashboard page.
    };

  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/api/auth/users`);
        console.log('Fetched Users:', response.data); // Log the user data
        setTotalUsers(response.data.length); // Set total users
        setUsers(response.data);
        setLoading(false); // Ensure loading is set to false after fetching
    } catch (error) {
        console.error('Error fetching flashcards:', error);
        setError('Error fetching flashcards');
    } finally {
        setLoading(false); // Ensure loading is set to false even on error
    }
}, [API_BASE_URL]);

useEffect(() => {
    fetchUsers();
}, [fetchUsers]);

  const approveUser = async (userId) => {
    await axios.patch(`${API_BASE_URL}/api/auth/approve`, { userId, is_approved: true, role: 'user' });
    setUsers(users.map(user => user.id === userId ? { ...user, is_approved: true } : user));
  };

  const sendVerificationEmail = async (userId) => {
    try {
      await axios.post(`${API_BASE_URL}/api/auth/send-verify-email`, { userId });
      alert('Verification email sent!');
    } catch (error) {
      console.error('Error sending verification email:', error);
      alert('Failed to send verification email.');
    }
  };

  // Filter users if a specific user is selected
  const filteredUser = selectedUser ? users.filter(user => user.id === parseInt(selectedUser)) : users;

  return (
    <div className='admin-container'>
      <h1 className='h1'>Admin Panel</h1>
      <button className="back" type="button" onClick={GoDashboard}>Back</button>
      <select value={selectedUser} onChange={(e) => setSelectedUser(e.target.value) & setSelectedAction('') } className="dropdown">
                <option value="">All Users {selectedUser==="" && `(${totalUsers})`}</option>
                {users.map((user) => (
                    <option key={user.id} value={user.id}>{user.username}</option>
                ))}
      </select>
      <div className="user-list">
        {filteredUser.map(user => (
          <div key={user.id} className="user-card">
            <p><strong>ID:</strong> {user.id}</p>
            <p><strong>Username:</strong> {user.username}</p>
            <p><strong>Email:</strong> {user.email}</p>
            <p><strong>Created At:</strong> {new Date(user.created_at).toLocaleDateString('en-TW')}</p>
            <p><strong>Verified:</strong> {user.is_verified ? 'Yes' : 'No'}</p>
            <p><strong>Approved:</strong> {user.is_approved ? 'Yes' : 'No'}</p>
            <p><strong>Role:</strong> {user.role}</p>
            <select value={selectedAction} onChange={(e) => setSelectedAction(e.target.value)} className="dropdown">
              <option value="">All Actions</option>
              <option value="Send Email">Send Verification Email</option>
              <option value="Approve">Approve</option>
            </select>
            {selectedAction === "Approve" && (
              <button className="approve-button" onClick={() => approveUser(user.id)}>Approve</button>
            )}
            {selectedAction === "Send Email" && (
              <button className="email-button" onClick={() => sendVerificationEmail(user.id)}>Send Verification Email</button>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Admin;