import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import FlashCard from './FlashCard'; // Import the FlashCard component
import './buttons.css';

function PlayAI() {
    const location = useLocation();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { mode, customAmount, folder } = location.state || { mode: 'all' };
    const [selectedFolder, setSelectedFolder] = useState(''); 
    const [flashcards, setFlashcards] = useState([]);
    const [currentCardIndex, setCurrentCardIndex] = useState(0);
    const [score, setScore] = useState(0);
    const [reactionTimes, setReactionTimes] = useState([]);
    const [startTime, setStartTime] = useState(null);
    const [isFlipped, setIsFlipped] = useState(false); 
    const [totalCards, setTotalCards] = useState(0); 
    const [userAnswer, setUserAnswer] = useState(''); // User's typed answer
    const [feedback, setFeedback] = useState(''); // Feedback from OpenAI
    const scoreRef = useRef(score);
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    // Fetch flashcards (same as before)
    const fetchFlashcards = useCallback(async () => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await axios.get(`${API_BASE_URL}/api/flashcards?user_id=${userId}`, {
                params: { folder: folder || '' },
            });
            let cards = response.data;
            if (mode === 'custom') {
                cards = cards.sort(() => Math.random() - 0.5).slice(0, customAmount);
            } else {
                cards = cards.sort(() => Math.random() - 0.5);
            }
            setFlashcards(cards);
            setTotalCards(cards.length);
        } catch (error) {
            console.error('Error fetching flashcards:', error);
        }
    }, [mode, customAmount, API_BASE_URL, selectedFolder]);

    useEffect(() => {
        const fetchAndSetStartTime = async () => {
            await fetchFlashcards();
            setStartTime(Date.now());
        };
        fetchAndSetStartTime();
    }, [fetchFlashcards]);

    const handleSubmitAnswer = async () => {
        const reactionTime = (Date.now() - startTime) / 1000;
        const currentCard = flashcards[currentCardIndex];
        console.log('Current card structure:', currentCard);
        setLoading(true);

        try {
            const response = await axios.post(`${API_BASE_URL}/api/checkanswer`, {
                cardFront: currentCard.front_content, // Front of the card
                userAnswer,
            });

            const { isCorrect, feedback } = response.data;
            console.log("Received Feedback:", feedback);
            setFeedback(feedback);

            if (isCorrect) {
                setScore((prev) => prev + 1);
            }
            scoreRef.current = score + (isCorrect ? 1 : 0);


            // Update flashcard stats
            const updatedFlashcard = {
                id: currentCard.id,
                tested_time: 1,
                correct_time: isCorrect ? 1 : 0,
            };
            await axios.put(`${API_BASE_URL}/api/scores/flashcards`, updatedFlashcard);

            setReactionTimes((prev) => [...prev, reactionTime]);
            setUserAnswer(''); // Clear input field
            //nextCard();
        } catch (error) {
            console.error('Error checking answer:', error);
        } finally {
            setLoading(false);
        }
    };

    const nextCard = () => {
        if (currentCardIndex < flashcards.length - 1) {
            setCurrentCardIndex((prev) => prev + 1);
            setStartTime(Date.now());
            setFeedback(''); // Reset feedback
            setIsFlipped(false);
        } else {
            setTimeout(() => {
                endGame();
            },0);
        }
    };

    const endGame = async () => {
        const userId = localStorage.getItem('userId');
        const totalReactionTime = Math.round(reactionTimes.reduce((a, b) => a + b, 0));
        const correctionRate = ((score / totalCards) * 100).toFixed(2);
        console.log('Score to database is', {scoreRef});
        try {
            await axios.post(`${API_BASE_URL}/api/scores`, {
                user_id: userId,
                score: scoreRef.current,
                total_reaction_time: totalReactionTime,
                total_cards: totalCards,
            });
            alert(`Game Over! \nTotal Cards: ${totalCards} \nYour score: ${scoreRef.current} \nCorrection rate: ${correctionRate}%`);
            navigate('/dashboard');
        } catch (error) {
            console.error('Error submitting score:', error);
        }
    };

    if (flashcards.length === 0) return <p>Loading...</p>;

    const currentCard = flashcards[currentCardIndex];

    return (
        <div className='ai-play'>
            <h2>Score: {scoreRef.current}</h2>
            <FlashCard 
                card={currentCard} 
                onFlip={() => setIsFlipped(false)} //trun off the flip function in this play mode. Original : setIsFlipped(prev => !prev)}  //Toggle the flip state
                isFlipped={isFlipped}  //Pass the isFlipped state
            />
            {feedback=='' && (
            <div>    
            <textarea
                type="text"
                value={userAnswer}
                onChange={(e) => setUserAnswer(e.target.value)}
                placeholder="Describe the card content & Type your answer here"
            />
            <button className="quit" onClick={endGame}>Quit</button>
            <button className='submit' type="submit" onClick={handleSubmitAnswer} disabled={loading}>
                  {loading ? 'loading answer...' : 'Submit'}
            </button>
            </div>
            )}
            <div>
                {feedback && (
                    <div>
                    <p>評語 : {feedback}</p>
                    <button className="quit" onClick={endGame}>Quit</button>
                    <button className="next" onClick={nextCard}>Next</button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default PlayAI;
